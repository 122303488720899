<template>
  <div class="content">
    <div class="content__title content__title--party">
      <div class="content__group">
        <h1 class="title title--big title--theme">Статистика</h1>
        <button @click="onExport" type="button" class="link link--right">
          <img src="@/assets/img/file/import-icon.svg" alt="Скачать">
          <span>Скачать Word</span>
        </button>
      </div>
      <div v-if="userRole === 'admin'" class="search">
        <v-select
          @search="onSearch"
          @input="onSetOrganization"
          v-model="region_result"
          :reduce="org => org.id"
          :filterable="false"
          :options="region_options"
          :get-option-label="getLabel"
          placeholder="Выберите региональную организацию"
          class="select"
        >
          <template slot="open-indicator">
            <svg class="select__open-indicator" xmlns="http://www.w3.org/2000/svg" width="23" height="23"
                 fill="none">
              <path clip-rule="evenodd" d="M10.06 17.25a7.19 7.19 0 100-14.38 7.19 7.19 0 000 14.38z"
                    stroke="#C7CFDD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
              <path d="M20.12 20.12l-4.98-4.98" stroke="#C7CFDD" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round"></path>
            </svg>
          </template>
          <template slot="no-options">Введите свой запрос для поиска</template>
          <template slot="option" slot-scope="option">
            <div class="select__item d-center">
              {{ option.name }} (ID: {{ option.id }}) ({{ option.group_name }})
            </div>
          </template>
          <template slot="selected-option" slot-scope="option">
            <div class="selected d-center">
              {{ option.name }} (ID: {{ option.id }}) ({{ option.group_name }})
            </div>
          </template>
        </v-select>
      </div>
    </div>
    <div v-if="statsLoaded" class="statistics">
      <div class="statistics__items">
        <div class="statistics__item">
          <div class="statistics__info">
            <div class="statistics__count">{{ cards.members.local }}</div>
            <p class="statistics__desc">Всего местных организаций</p>
          </div>
        </div>
        <div class="statistics__item">
          <div class="statistics__info">
            <div class="statistics__count">{{ cards.members.primary }}</div>
            <p class="statistics__desc">Всего первичных групп</p>
          </div>
        </div>
      </div>
      <div class="statistics__items">
        <div class="statistics__item statistics__item--success">
          <div class="statistics__info">
            <div class="statistics__count">{{ cards.members.accept }}</div>
            <p class="statistics__desc">Принято в РСМ за истекший год</p>
          </div>
        </div>
        <div class="statistics__item statistics__item--abort">
          <div class="statistics__info">
            <div class="statistics__count">{{ cards.members.refuse }}</div>
            <p class="statistics__desc">Выбыло (снято с учета) из РСМ за истекший год</p>
          </div>
        </div>
      </div>
      <div class="statistics__items">
        <div class="statistics__item">
      <div class="statistics__info">
        <div class="statistics__count">{{ cards.active_members.count }}</div>
        <p class="statistics__desc">Активных индивидуальных членов РСМ</p>
      </div>
    </div>
        <div class="statistics__item">
          <div class="statistics__info">
            <div class="statistics__count">{{ cards.members.count }}</div>
            <p class="statistics__desc">Всего индивидуальных членов РСМ</p>
          </div>
        </div>
        <div class="statistics__item">
          <div class="statistics__info">
            <div class="statistics__count">{{ cards.age.from_14_to_18 }}</div>
            <p class="statistics__desc">Индивидуальных членов РСМ в возрасте от 14 до 18 лет</p>
          </div>
        </div>
        <div class="statistics__item">
          <div class="statistics__info">
            <div class="statistics__count">{{ cards.age.from_18_to_30 }}</div>
            <p class="statistics__desc">Индивидуальных членов РСМ в возрасте от 18 до 30 лет</p>
          </div>
        </div>
        <div class="statistics__item">
          <div class="statistics__info">
            <div class="statistics__count">{{ cards.age.from_30_to_35 }}</div>
            <p class="statistics__desc">Индивидуальных членов РСМ в возрасте от 30 до 35 лет</p>
          </div>
        </div>
        <div class="statistics__item">
          <div class="statistics__info">
            <div class="statistics__count">{{ cards.age.from_35 }}</div>
            <p class="statistics__desc">Индивидуальных членов РСМ старше 35 лет</p>
          </div>
        </div>
      </div>
      <div class="statistics__holder">
        <div class="statistics__party">
          <h2 class="title">График вступления членов в РСМ</h2>
        </div>
        <div class="statistics__filters">
          <div class="statistics__select">
            <v-select
              @input="onSetPeriod"
              v-model="filter_result"
              :reduce="org => org.id"
              :searchable="false"
              :clearable="false"
              :options="filter_options"
              :get-option-label="getLabel"
              placeholder="Выберите период"
              class="select"
            >
              <template slot="open-indicator">
                <svg width="18" height="18" fill="none" class="open-indicator" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>
                  <path d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z" fill="#fff"></path>
                </svg>
              </template>
              <template slot="no-options">Выберите период</template>
              <template slot="option" slot-scope="option">
                <div class="select__item d-center">
                  {{ option.name }}
                </div>
              </template>
              <template slot="selected-option" slot-scope="option">
                <div class="selected d-center">
                  {{ option.name }}
                </div>
              </template>
            </v-select>
          </div>
          <form v-if="filter_result === 5" class="form" @submit.prevent="fetchGraph('', date_start, date_end)">
            <div class="form__content">
              <div class="form-control statistics__input">
                <masked-input
                  v-model="date_start"
                  name="date_start"
                  mask="11.11.1111"
                  placeholder="дд.мм.гггг"
                  class="form-control__input"
                  id="date_start"
                  autocomplete="off"
                />
              </div>
              <div class="form-control statistics__input">
                <masked-input
                  v-model="date_end"
                  name="date_start"
                  mask="11.11.1111"
                  placeholder="дд.мм.гггг"
                  class="form-control__input"
                  id="date_end"
                  autocomplete="off"
                />
              </div>
              <button
                class="button button--mini"
                type="submit"
                :disabled="!startDateWrong"
              >
                Применить
              </button>
            </div>
          </form>
        </div>
        <div class="statistics__graph">
          <Chart :chart-data="chartData" :options="chartOptions" :height="chartHeight" />
        </div>
      </div>
    </div>
    <div class="loading loading--content" id="loading" v-if="!statsLoaded">
      <div class="loading__body">
        <div class="effect-1 loading__effects"></div>
        <div class="effect-2 loading__effects"></div>
        <div class="effect-3 loading__effects"></div>
      </div>
    </div>
  </div>
</template>

<script>
import {debounce} from 'lodash'
import Chart from '@/components/Chart'
import MaskedInput from 'vue-masked-input'

export default {
  name: 'Statistics',
  components: {
    Chart,
    MaskedInput,
  },
  data() {
    return {
      cards: {
        age: {},
        members: {},
        members_strange: {},
      },
      region_result: '',
      region_options: [],
      statsLoaded: false,
      chartData: {},
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        scales: {
          xAxes: [{
            gridLines: {
              display:false
            }
          }],
          yAxes: [{
            gridLines: {
              display:false
            }
          }]
        }
      },
      chartHeight: 300,
      date_start: '',
      date_end: '',
      filter_result: 1,
      filter_options: [
        {id: 1, name: 'Весь период'},
        {id: 2, name: 'Текущий год'},
        {id: 3, name: 'Текущий квартал'},
        {id: 4, name: 'Текущий месяц'},
        {id: 5, name: 'Произвольный период'},
      ]
    }
  },
  computed: {
    userRegion() {
      return this.$store.state.organization
    },
    userRole() {
      return this.$store.state.userRole
    },
    startDateWrong() {
      return this.date_start.replaceAll('_', '').length === 10
    },
    currentYear() {
      return new Date().getFullYear()
    },
    lastDayMonth() {
      const month = new Date().getMonth()
      const year = new Date().getFullYear()
      return new Date(year, month + 1, 0).toLocaleDateString('RU-ru')
    },
    firstDayMonth() {
      return `01${this.lastDayMonth.slice(-8)}`
    },
  },
  created() {
    this.fetchCards()
    this.fetchGraph('', '01.01.1991')
    // this.calculateMonthDate()
  },
  methods: {
    fetchCards(region_org = '') {
      if (!region_org) region_org = ''
      this.$store.dispatch('stats/GET_CARDS', region_org)
        .then(response => {
          this.cards = response.data
          this.statsLoaded = true
        })
        .catch(() => {
          this.$notify({
            type: 'error',
            title: 'Ошибка!',
            text: 'Ошибка получения статистики'
          })
        })
    },
    fetchGraph(year = this.currentYear, from = '', to = '') {
      this.$store.dispatch('stats/GET_GRAPH', { year, from, to })
        .then(response => {
          this.chartData = {
            labels: Object.keys(response.data),
            datasets: [
              {
                backgroundColor: 'rgba(43, 147, 231, 0.15)',
                borderColor: '#2B93E7',
                pointBackgroundColor: '#2B93E7',
                data: Object.values(response.data)
              }
            ]
          }
          this.statsLoaded = true
        })
        .catch(() => {
          this.$notify({
            type: 'error',
            title: 'Ошибка!',
            text: 'Ошибка получения статистики'
          })
        })
    },
    onSetOrganization() {
      this.fetchCards(this.region_result)
    },
    onSearch(search, loading) {
      loading(true)
      this.searchOrg(loading, search, this)
    },
    searchOrg: debounce((loading, search, vm) => {
      vm.$store.dispatch('regOrg/GET_QUERY', search).then(response => {
        vm.region_options = response.data
        loading(false)
      })
    }, 350),
    getLabel(option) {
      if (typeof option === 'object') {
        if (Object.prototype.hasOwnProperty.call(!option, this.label)) {
          return console.warn(
            `[vue-select warn]: Label key "option.${this.label}" does not` +
            ` exist in options object ${JSON.stringify(option)}.\n` +
            'https://vue-select.org/api/props.html#getoptionlabel'
          )
        }
        return option[this.label]
      }
      return option
    },
    calculateQuarterDays(first = true) {
      const month = new Date().getMonth()
      const year = new Date().getFullYear()
      const start = (Math.floor(month/3)*3)+1
      const end = start+3
      const startDate = new Date(start+'.01.'+ year).toLocaleDateString('RU-ru')
      let endDate = end>12?new Date('01.01.'+ (year+1)):new Date(end+'.01.'+ (year))
      endDate = new Date((endDate.getTime())-1).toLocaleDateString('RU-ru')
      if (first) return startDate
      else return endDate
    },
    onSetPeriod() {
      if (this.date_start || this.date_end) {
        this.date_start = ''
        this.date_end = ''
      }
      switch (this.filter_result) {
        case 1:
          this.fetchGraph('', '01.01.1991')
          break
        case 2:
          this.fetchGraph(this.currentYear)
          break
        case 3:
          this.fetchGraph('', this.calculateQuarterDays(), this.calculateQuarterDays(false) )
          break
        case 4:
          this.fetchGraph('', this.firstDayMonth, this.lastDayMonth )
          break
      }
    },
    onExport() {
      this.$store.dispatch('stats/GET_EXPORT')
        .then(response => {
          const url = URL.createObjectURL(response.data)
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'Экспорт_из_Статистики.docx')
          document.body.appendChild(link)
          link.click()
          URL.revokeObjectURL(link.href)
        })
        .catch(error => {
          error.response.data.text().then(res => {
            const errorJson = JSON.parse(res)
            for (const key in errorJson) {
              if (typeof errorJson[key] === 'string') {
                this.$notify({
                  type: 'error',
                  title: 'Внимание!',
                  text: errorJson[key]
                })
              } else {
                this.$notify({
                  type: 'error',
                  title: 'Внимание!',
                  text: errorJson[key][0]
                })
              }
            }
          })
        })
    }
  }
}
</script>

<style lang="sass">
@import "@/assets/common/index.scss"

.statistics__items
  display: grid
  grid-template-columns: repeat(auto-fill, 292px)
  column-gap: 15px
  row-gap: 20px
  margin-bottom: 40px

.statistics__item
  min-height: 100px
  padding: 15px
  border-radius: 7px
  background-image: linear-gradient(256.92deg, #7EC7FF 0.85%, #1D87DD 98.53%)
  box-shadow: 0 2px 6px rgba(43, 147, 231, 0.25)

.statistics__item--abort
  background-image: linear-gradient(256.92deg, #FFBDC8 0.85%, #EA5467 98.53%)

.statistics__item--success
  background-image: linear-gradient(256.21deg, #BDE9C8 0%, #38BF4E 96.91%)

.statistics__item--neutral
  background-image: linear-gradient(256.21deg, #FADCB6 0%, #FF9416 96.91%)

.statistics__item--archive
  background-image: linear-gradient(256.21deg, #DDDDDD 0%, #AAAAAA 96.91%)

.statistics__count
  color: #fff
  font-size: 24px
  font-weight: 700

.statistics__desc
  color: #fff
  font-size: 14px

.statistics__holder
  padding: 40px
  border-radius: 10px
  background-color: #F8FDFF

.statistics__party
  display: flex
  justify-content: space-between
  align-items: center
  margin-bottom: 10px

.statistics__dates
  color: $color-theme
  font-size: 16px
  font-weight: 600

.statistics__graph

  & > div
   overflow: visible!important

  .highcharts-credits
    display: none

.statistics__filters
  display: flex
  margin-bottom: 30px

.statistics__select
  flex-grow: 1
  max-width: 500px
  margin-right: 20px

.statistics__input
  width: 300px
  margin-right: 20px
</style>